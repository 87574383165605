<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-12 pt-0 pb-2">
        <h1>Leave Requests</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-simple-table
            fixed-header
            height="450px"
        >
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-left">Person</th>
              <th class="text-left">Days requested</th>
              <th class="text-left">Start date</th>
              <th class="text-left">End date</th>
              <th class="text-left">Coverage</th>
              <th>&nbsp;</th>
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="(request, index) in requests"
                :key="index"
            >
              <td>{{ request.personnel.first_name }} {{ request.personnel.last_name }}</td>
              <td>{{ request.total_days }}</td>
              <td>{{ request.first_day | prettyDate }}</td>
              <td>{{ request.last_day | prettyDate }}</td>
              <td>{{ request.coverage }}</td>
              <td>
                <v-btn
                  color="green"
                  x-small
                  @click="confirmRequest(index)">
                  Confirm
                </v-btn>
                &nbsp;
                <v-btn
                  color="red"
                  x-small
                  @click="rejectRequest(index)">
                  Reject
                </v-btn>
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-pagination
            class="pt-4"
            v-model="page"
            :length="length"
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
            :total-visible="totalVisible"
        ></v-pagination>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from '../../../axios';

export default {
  name: 'AdminLeaveRequests',
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      requests: [],
      page: 1,
      length: 0,
      totalVisible: 9,
      warnArchive: false,
      toArchive: -1,
    };
  },
  watch: {
    page() {
      this.getRequests();
    },
  },
  methods: {
    confirmRequest(index) {
      const requestId = this.requests[index].id;
      axios.get(`/leaveRequests/confirm/${requestId}.json?token=${this.token}`)
        .then(() => {
          this.requests.splice(this.toArchive, 1);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getRequests() {
      axios.get(`/leaveRequests/getOutstandingRequests/${this.page}.json?token=${this.token}&archived=0`)
          .then((response) => {
            this.requests = response.data.leaveRequests;
            this.length = response.data.length;
          })
          .catch((error) => {
            console.log(error);
          });
    },
    rejectRequest(index) {
      const requestId = this.requests[index].id;
      axios.get(`/leaveRequests/reject/${requestId}.json?token=${this.token}`)
          .then(() => {
            this.requests.splice(this.toArchive, 1);
          })
          .catch((error) => {
            console.log(error);
          });
    },
  },
  mounted() {
    this.getRequests();
  },
};
</script>
